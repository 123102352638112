import { CheckCircleIcon, XMarkIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';

export default function Alert({ isOpen, onClose, message, positive }) {
    const [type, setType] = useState(positive);

    useEffect(() => {
        if (isOpen) {
            setType(positive);
            const interval = setTimeout(onClose, 3000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [isOpen, positive, onClose]);

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <div data-testid="alert-container" className={`rounded-md p-4 fixed top-8 left-[50%] translate-x-[-50%] w-full max-w-[380px] z-50 ${type ? 'bg-green-50' : 'bg-red-50'}`}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        {type ? (
                            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        ) : (
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        )}
                    </div>
                    <div className="ml-3">
                        {type ? (
                            <p className="text-sm font-medium text-green-800">{message}</p>
                        ) : (
                            <p className="text-sm font-medium text-red-800">{message}</p>
                        )}
                    </div>
                    <div className="ml-auto pl-3">
                        <div className="-mx-1.5 -my-1.5">
                            {type ? (
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Transition.Root>
    );
}
